<template>

  <h3 class="s-title">Profile</h3>

  <div class="panel">
    <div class="panel-header text-center">
      <div class="panel-title h5 mt-10">
        <input id="username-form" class="form-input col-6 mb-1" v-model="userProfile.username" placeholder="New username">
      </div>
    </div>
    <nav class="panel-nav">
      <ul class="tab tab-block">
        <li @click="tab('interests')" :class="currentTab == 'interests' ? 'active' : ''"
        class="tab-item c-hand"><a>Interests</a></li>
        <li @click="tab('annotations')" :class="currentTab == 'annotations' ? 'active' : ''"
        class="tab-item c-hand"><a>My Annotations</a></li>
      </ul>
    </nav>

    <!-- Interests -->
    <div class="panel-body" v-if="currentTab == 'interests'">
      <div id="empty-interests" class="columns"
      v-if='!userProfile.tags || userProfile.tags.length < 1'>
        <div id="message-container" class="col-lg-11 col-8">
          <p class="h5">Nothing to see here!</p>
          <p class="p-2">Looks like you haven't created any annotations yet. Once you have annotated articles, they will appear here. If you're unsure how to do this, see our <a @click="$router.push('/about')" class="c-hand">about page</a></p>
        </div>
      </div>
      <div v-for="tag in Object.keys(userProfile.tags)" :key="tag" class="tile tile-centered">
        <div class="tile-content">
          <div class="tile-subtitle">{{ tag }}</div>
        </div>
        <div class="tile-action">
        <button class="btn btn-link btn-action btn-lg tooltip tooltip-left" @click="deleteInterest(tag)"
        data-tooltip="Delete interest">
          <i class="icon icon-cross"></i>
        </button>
        </div>
      </div>
    </div>
    <!-- Interests -->

    <!-- Annotations -->
    <div class="panel-body" v-if="currentTab == 'annotations'">
      <div id="empty-annotations" class="columns"
      v-if='myAnnotations.length < 1'>
        <div id="message-container" class="col-lg-11 col-8">
          <p class="h5">Nothing to see here!</p>
          <p class="p-2">Looks like you haven't created any annotations yet. Once you have annotated articles, they will appear here. If you're unsure how to do this, see our <a @click="$router.push('/about')" class="c-hand">about page</a></p>
        </div>
      </div>
      <div v-for="article_id, annotation_id in myAnnotations" :key="annotation_id">
        <Annotation class="c-hand" @click="$router.push('/view_article/' + article_id)" :annotation_id="annotation_id" />
      </div>
    </div>
    <!-- Annotations -->

    <div class="panel-footer">
      <button @click="saveChanges()" class="btn btn-primary btn-block">Save</button>
    </div>
  </div>

</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import axiosBackendAPI from '../lib/axiosBackendAPI.js'
  import Annotation from '../components/Annotation.vue'


  export default {
    name: 'Profile',
    components: {
      Annotation
    },
    data() {
      return {
        currentTab: "interests",
        myAnnotations: []
      }
    },
    computed: mapGetters(["userAuth", "userProfile", "availableFilters"]),
    methods: {
      ...mapActions(["fetchProfile", "setError"]),
      tab(section) {
        this.currentTab = section
      },
      async getMyAnnotations() {
        let headers = {
          Authorization: `Bearer ${this.userAuth.accessToken}`
        }
        let res = await axiosBackendAPI.get(process.env.VUE_APP_ANNOTATION_SERVER_ENDPOINT + '/my_annotations', {
          headers: headers,
        })
        return this.myAnnotations = res.data
      },
      deleteInterest(tag) {
        delete this.userProfile.tags[tag]
      },
      async saveChanges() {
        let headers = {
          Authorization: `Bearer ${this.userAuth.accessToken}`
        }
        await axiosBackendAPI.put(process.env.VUE_APP_USER_SERVER_ENDPOINT + '/profile',
          this.userProfile,
          { headers: headers }
        )
        this.fetchProfile()
        this.$router.push('/')
      },
    },
    created() {
      if (!this.userAuth.signedIn) {
        console.log("Not signed in, redirecting to /")
        this.$router.push('/')
      }
      this.getMyAnnotations()
    }
  }
</script>

<style>
  #empty-annotations, #empty-interests {
    margin-top: 5rem;
    text-align: center;
    margin: 5rem auto;
  }

  #message-container, #username-form {
    margin: auto;
  }
</style>