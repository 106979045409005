<template>
  <div class="docs-container off-canvas off-canvas-sidebar-show">
    <Header />
    <Sidebar />
    <div class="off-canvas-content">
      <div class="docs-content" id="content">
        <div class="columns">
          <div class="column col-7 col-xl-12">
            <router-view/>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ErrorModal />
  </div>
</template>

<script>
  import Header from './components/Header.vue'
  import Sidebar from './components/Sidebar.vue'
  import ErrorModal from './components/ErrorModal.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Sidebar,
      ErrorModal,
      Footer
    }
  }
</script>

<style>
  body {
    font-size: .875rem;
  }

  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }

  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }

</style>