<template>
  <h3 class="s-title">News feed</h3>

  <div v-show="newsIsLoading" class="loading loading-lg"></div>
  <!-- Show empty state if articles are empty -->
  <div v-show="allNews.length == 0 && !newsIsLoading" class="empty">
    <div class="empty-icon">
      <i class="icon icon-bookmark"></i>
    </div>
    <p class="empty-title h5">Nothing to see here...</p>
    <p class="empty-subtitle">It looks like we have nothing for you at the moment</p>
    <p class="empty-subtitle">Try adjusting your filters or check back later on</p>
    <div class="empty-action">
      <button class="btn btn-primary">Clear filters</button>
    </div>
  </div>

  <!-- Else show normal article output -->
  <div v-show="allNews.length != 0 && !newsIsLoading" v-for="article in allNews" :key="article.id"
  @click="this.$router.push('/view_article/' + article.id)" class="tile c-hand">
    <div class="col-12 tile-content" :class="{ 'col-9': article.urlToImage }">
      <p class="tile-title">{{ article.title }}</p>
      <small class="text-gray">Published by {{ article.source.name }} on {{ article.publishedAt }}</small>
      <div class="stats">
        <button class="btn btn-primary btn-sm badge" v-bind:data-badge="article.trustiness">
          Trustiness
        </button>
        <button class="btn btn-sm badge" v-bind:data-badge="article.annotationCount">
          Annotations
        </button>
      </div>
    </div>
    <div class="col-3" v-if="article.urlToImage">
      <img class="img-responsive" loading="lazy" :src="article.urlToImage" />
    </div>
  </div>
</template>

<script type="text/javascript">
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "PopularNews",
    methods: {
      ...mapActions(['fetchNews'])
    },
    computed: mapGetters(["allNews", "newsIsLoading"]),
    created() {
      if (Object.entries(this.allNews).length == 0) {
        this.fetchNews()
      }
    }
  }
</script>

<style>
  .tile {
    border: 1.5px solid #dadee4;
    border-radius: 5px;
    margin: 1rem 1rem 1rem 0rem;
    padding: 1rem 1rem 0.5rem 1rem;
  }

  .tile-title {
    font-size: medium;
  }

  img {
    border-radius: 10px;
  }

  p, small {
    margin-bottom: 0.6rem;
  }

  .stats {
    margin-top: 0.75rem;
  }

  .badge {
    margin-right: 15px;
  }

  @media (max-width: 960px) {
    .tile {
      border: 1.5px solid #dadee4;
      border-radius: 5px;
      margin: auto;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
    }
  }
</style>
