import { createStore } from 'vuex'
import axios from 'axios'
import axiosBackendAPI from '../lib/axiosBackendAPI.js'

let store = createStore({
  state: {
    error: {
      isPresent: false,
      title: "",
      message: ""
    },
    interfaceOptions: {
      showSidebar: false
    },
    news: {
      isLoading: false,
      currentArticle: {},
      all: {},
    },
    user: {
      auth: {
        signedIn: false,
        accessToken: ""
      },
      profile: {
        tags: {},
        filters: {
          country: "",
          category: "",
          sortBy: "clicks",
          maxAgeSeconds: 172800
        }
      }
    },
    filterOptions: {
      categories: {
        "All": "",
        "General": "general",
        "Business": "business",
        "Health": "health",
        "Entertainment": "entertainment",
        "Science": "science",
        "Sports": "sports",
        "Technology": "technology"
      },
      countries: {
        "Global": "",
        "Australia": "au",
        "Canada": "ca",
        "India": "in",
        "New Zealand": "nz",
        "South Africa": "za",
        "United Kingdom": "gb",
        "United States": "us"
      },
      sortBy: {
        "Popularity": "clicks",
        "Date": "publishedAt",
        "Recommended": "recommended"
      }
    }
  },
  mutations: {
    setError: (state, error) => state.error = error,
    setNewsIsLoading: (state, isLoading) => state.news.isloading = isLoading,
    setNews: (state, news) => state.news.all = news,
    setUserFilters: (state, filters) => state.user.profile.filters = filters,
    setUserProfile: (state, data) => state.user.profile = data,
    setAuthData: (state, data) => state.user.auth = data,
    setCurrentArticle: (state, article) => state.news.currentArticle = article,
    setInterfaceOptions: (state, options) => state.interfaceOptions = options
  },
  getters: {
    error: state => state.error,
    userAuth: state => state.user.auth,
    userProfile: state => state.user.profile,
    currentArticle: state => state.news.currentArticle,
    allNews: state => state.news.all,
    recommendedNews: state => state.news.recommended,
    newsIsLoading: state => state.news.isloading,
    availableFilters: state => state.filterOptions,
    selectedFilters: state => state.user.profile.filters,
    interfaceOptions: state => state.interfaceOptions
  },
  actions: {
    async fetchArticle({ commit }, article_id) {
      let headers = {}
      let user = this.state.user

      if (user.auth.signedIn) {
        headers = {
          Authorization: `Bearer ${user.auth.accessToken}`
        }
      }
      const response = await axiosBackendAPI.get(process.env.VUE_APP_NEWS_SERVER_ENDPOINT + '/article', {
        headers: headers,
        params: {
          article_id: article_id
        }
      })
      commit('setCurrentArticle', response.data)
    },
    async fetchNews({ commit }) {
      commit('setNewsIsLoading', true)
      let headers = {}
      let user = this.state.user

      if (user.auth.signedIn) {
        headers = {
          Authorization: `Bearer ${user.auth.accessToken}`
        }
      }
      const response = await axiosBackendAPI.get(process.env.VUE_APP_NEWS_SERVER_ENDPOINT, {
        headers: headers,
        params: {
          country: user.profile.filters.country,
          category: user.profile.filters.category,
          max_age_seconds: user.profile.filters.maxAgeSeconds,
          sort_by: user.profile.filters.sortBy
        }
      });
      commit('setNews', response.data)
      commit('setNewsIsLoading', false)
    },
    async fetchProfile({ commit }) {
      let user = this.state.user
      let headers = {
        Authorization: `Bearer ${user.auth.accessToken}`
      }
      let res = await axiosBackendAPI.get(process.env.VUE_APP_USER_SERVER_ENDPOINT + '/profile', { headers: headers })
      if (res.status == 200) {
        commit('setUserProfile', res.data)
      }
    },
    async login({ commit, dispatch }, accessToken) {
      // Access /login endpoint to create user (if they dont exist)
      // and fetch our own application's access & refresh tokens
      let headers = {
        Authorization: `Bearer ${accessToken}`
      }
      let res = await axios.get(process.env.VUE_APP_USER_SERVER_ENDPOINT + '/login',
        {
          headers: headers,
          withCredentials: true
        })

      // Commit access token to Vuex state (refresh token is HttpOnly cookie so we can ignore that)
      let auth = {}
      if (res.status == 200 || res.status == 201) {
        auth = {
          signedIn: true,
          accessToken: res.data.access_token
        }
      }
      commit('setAuthData', auth)
      dispatch('fetchProfile')
    },
    updateFilters({ commit }, filters) {
      commit('setUserFilters', filters)
    },
    updateInterfaceOptions({ commit }, options) {
      commit('setInterfaceOptions', options)
    },
    setError({ commit }, error) {
      commit('setError', error)
    }
  }
})

export default store
