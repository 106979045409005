<template>
  <div class="modal" :class="{ active: error.isPresent }" id="modal-id">
    <a @click="clearError()" class="modal-overlay c-hand" aria-label="Close"></a>
    <div class="modal-container">
      <div class="modal-header">
        <a @click="clearError()" class="btn btn-clear float-right c-hand" aria-label="Close"></a>
        <div class="modal-title h5">{{ error.title }}</div>
      </div>
      <div class="modal-body">
        <div class="content">
          <p>{{ error.message }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <a class="btn btn-link c-hand" @click="clearError()" >Close</a>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "ErrorModal",
    computed: mapGetters(["error"]),
    methods: { 
      ...mapActions(["setError"]),
      clearError() {
        console.log(this.error)
        let error = {
          isPresent: false,
          title: '',
          message: ''
        }
        this.setError(error)
      }
    }
  }
</script>