<template>
  <div v-if="!deleted" class="tile columns" v-show="annotation.message">
    <div class="col-11">
      <div class="tile-content">
        <span class="badge" v-bind:data-badge="annotation.user_rating">{{ annotation.user }}</span>
        <blockquote>
          <small><cite>{{ annotation.quote }}</cite></small>
        </blockquote>
        <p>{{ annotation.message }}</p>
        <small>Source: {{ annotation.source }}</small>
      </div>
    </div>
    <div class="col-1 p-centered text-center" id="votes">
      <div id="upvote">
        <i @click="vote('upvote')" :class="{ 'text-success': annotation.user_upvoted }"
        class="icon icon-2x icon-arrow-up c-hand"></i>
      </div>
      <div>
        <p class="mb-0">{{ annotation.upvotes - annotation.downvotes }}</p>
      </div>
      <div id="downvote">
        <i @click="vote('downvote')" :class="{ 'text-error': annotation.user_downvoted }"
        class="icon icon-2x icon-arrow-down c-hand"></i>
      </div>
      <div v-if="annotation.user_owns" id="delete">
        <i @click="deleteAnnotation()" class="icon icon-2x icon-delete c-hand mt-2"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import axiosBackendAPI from '../lib/axiosBackendAPI.js'

  export default {
    name: "Annotation",
    props: ["annotation_id"],
    methods: {
      async getAnnotation() {
        let headers = {}
        if (this.userAuth.signedIn) {
          headers = {
            Authorization: `Bearer ${this.userAuth.accessToken}`
          }
        }
        let res = await axiosBackendAPI.get(process.env.VUE_APP_ANNOTATION_SERVER_ENDPOINT, {
          headers: headers,
          params: {
            annotation_id: this.annotation_id
          }
        })
        return this.annotation = res.data
      },
      async vote(action) {
        let headers = {
          Authorization: `Bearer ${this.userAuth.accessToken}`
        }
        if (action == 'downvote' && this.annotation.user_downvoted) {
          action = 'clear'
        }
        if (action == 'upvote' && this.annotation.user_upvoted) {
          action = 'clear'
        }
        await axiosBackendAPI.post(process.env.VUE_APP_ANNOTATION_SERVER_ENDPOINT + "/vote",
          {
            annotation_id: this.annotation_id,
            action: action
          },
          { headers: headers }
        )
        this.getAnnotation()
      },
      async deleteAnnotation() {
        let headers = {}
        if (this.userAuth.signedIn) {
          headers = {
            Authorization: `Bearer ${this.userAuth.accessToken}`
          }
        }
        await axiosBackendAPI.delete(process.env.VUE_APP_ANNOTATION_SERVER_ENDPOINT, {
          headers: headers,
          params: {
            annotation_id: this.annotation_id
          }
        })
        this.deleted = true
      }
    },
    data() {
      return {
        annotation: {},
        deleted: false
      }
    },
    computed: mapGetters(["userAuth"]),
    created() {
      this.getAnnotation()
    }
  }
</script>

<style>
  .tile {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 1rem;
  }
</style>