import axios from 'axios'
import store from '../store'

// Axios instance for querying our own backend APIs
const axiosBackendAPI = axios.create();

async function refreshToken() {
  return axios.get(process.env.VUE_APP_USER_SERVER_ENDPOINT + '/refresh', {
    withCredentials: true
  })
    .then(function (res) {
      if (res.status == 200) {
        console.log("Refreshed tokens")
        return res.data.access_token
      } else {
        console.log("Failed to refresh tokens")
        return false
      }
    })
    .catch(function (err) {
      console.log("Failed to refresh tokens with ", err)
      return Promise.resolve(false)
    })
}

// Response interceptor to catch 401s
// If backend APIs respond with 401, regenerate token pair
// and resend the request with new access token
axiosBackendAPI.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let new_access_token = await refreshToken();
    if (new_access_token) {
      let authData = {
        signedIn: true,
        accessToken: new_access_token
      }
      store.commit('setAuthData', authData)
      originalRequest.headers['Authorization'] = `Bearer ${new_access_token}`
      return axios(originalRequest);
    } else {
      let authData = {
        signedIn: false,
        accessToken: ""
      }
      store.commit('setAuthData', authData)
      return Promise.resolve(false)
    }
  }
  return Promise.reject(error);
})

export default axiosBackendAPI