<template>
  <Header />
  <div class="container-fluid">
    <h1>About</h1>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style>
</style>