<template>
  <form @submit.prevent="submitAnnotation()" class="form-horizontal" action="#forms">
    <div class="form-group">
      <div class="col-3 col-sm-12">
        <label class="form-label" for="input-example-6">Quote</label>
      </div>
      <div class="col-9 col-sm-12">
        <textarea class="form-input" id="input-example-6" v-model="annotation.quote"
        placeholder="Exact word for word quote from the article" rows="2"></textarea>
      </div>
    </div>
    <div class="form-group">
      <div class="col-3 col-sm-12">
        <label class="form-label" for="input-example-6">Message</label>
      </div>
      <div class="col-9 col-sm-12">
        <textarea class="form-input" id="input-example-6" v-model="annotation.message"
        placeholder="Your correction, commentary or explanatory note. A factual statement, not your opinion..." rows="4"></textarea>
      </div>
    </div>
    <div class="form-group">
      <div class="col-3 col-sm-12">
        <label class="form-label" for="input-example-5">Source</label>
      </div>
      <div class="col-9 col-sm-12">
        <input class="form-input" id="input-example-5" v-model="annotation.source"
        placeholder="A source to backup your information">
      </div>
      <button class="submit-button btn btn-primary mt-2">Submit annotation</button>
    </div>
  </form>
  <div class="tile columns">
    <div class="col-11">
      <div class="tile-icon">
        <figure class="avatar"><img src="/assets/img/avatar-1.png" alt="Avatar"></figure>
      </div>
      <div class="tile-content">
        <p class="tile-title text-bold">{{ userProfile.username }}</p>
        <blockquote>
          <small>{{ annotation.quote }}</small>
        </blockquote>
        <p>{{ annotation.message }}</p>
        <small>Source: <cite>{{ annotation.source }}</cite></small>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import axiosBackendAPI from '../lib/axiosBackendAPI.js'

  export default {
    name: "AnnotationForm",
    emits: ["submitted"],
    computed: mapGetters(["currentArticle", "userAuth", "userProfile"]),
    data() {
      return {
        annotation: {
          quote: "",
          message: "",
          source: ""
        },
      }
    },
    methods: {
      async submitAnnotation() {
        let headers = {
          Authorization: `Bearer ${this.userAuth.accessToken}`
        }
        let annotation = this.annotation
        let article_id = this.currentArticle.id
        let data = {
          article_id: article_id,
          quote: annotation.quote,
          message: annotation.message,
          source: annotation.source
        }
        await axiosBackendAPI.post(process.env.VUE_APP_ANNOTATION_SERVER_ENDPOINT,
          data,
          { headers: headers },
        )
        this.$emit('submitted')
      }
    }
  }
</script>

<style>
  .submit-button {
    margin-left:  auto;
  }
</style>
