<template>
  <div class="docs-footer container grid-lg" id="copyright">
    <p>
      <a class="c-hand" @click="this.$router.push('/about')">About</a> · <a class="c-hand" @click="this.$router.push('/privacy ')">Privacy</a>
    </p>.
  </div>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>