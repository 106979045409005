import { createRouter, createWebHistory } from 'vue-router'
import NewsFeed from '../views/NewsFeed.vue'
import ArticleView from '../views/ArticleView.vue'
import Profile from '../views/Profile.vue'
import Privacy from '../views/Privacy.vue'
import About from '../views/About.vue'


const routes = [
  {
    path: '/',
    name: 'NewsFeed',
    component: NewsFeed
  },
  {
    path: '/view_article/:id',
    name: 'ArticleView',
    component: ArticleView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router