<template>
  <div class="docs-navbar">
    <a class="off-canvas-toggle btn btn-link btn-action" @click="showSidebar()">
      <i class="icon icon-menu"></i>
    </a>
    <div class="btns d-flex">
      <div v-if="!userAuth.signedIn" class="alignLeft" id="loginButton"></div>
      <a v-if="userAuth.signedIn" class="btn btn-primary alignLeft c-hand"
        @click="$router.push('/profile')">{{ userProfile.username }}'s profile</a>
      <a v-if="userAuth.signedIn" class="btn btn-secondary ml-1">Rating: {{ userProfile.rating }}</a>
    </div>
  </div>
</template>

<script type="text/javascript">
  import { mapGetters, mapActions } from "vuex";
  import store from '../store'

  export default {
    name: "Header",
    methods: {
      ...mapActions(['updateInterfaceOptions']),
      showSidebar() {
        this.interfaceOptions.showSidebar = true
        this.updateInterfaceOptions(this.interfaceOptions)
      }
    },
    computed: mapGetters(["userAuth", "userProfile", "interfaceOptions"]),
  }

  async function handleCredentialResponse(response) {
    store.dispatch('login', response.credential)
  }

  window.onload = async function () {
    await store.dispatch('fetchProfile')
    if (!store.getters.userAuth.signedIn) {
      window.google.accounts.id.initialize({
        client_id: "148728416890-21k8bhhfpnk2kfmkip0n7gu44mppm9n1.apps.googleusercontent.com",
        auto_select: true,
        callback: handleCredentialResponse
      });
      window.google.accounts.id.renderButton(
        document.getElementById("loginButton"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      window.google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }
</script>

<style type="text/css">
  .alignLeft {
    margin-left: auto;
  }
  #loginButton {
    padding: 3px;
  }
</style>
