<template>
  <h5 class="s-title c-hand" @click="$router.go(-1)"><i class="icon icon-back" ></i>        Go back</h5>
  <div v-if="currentArticle.id != this.$route.params.id" class="loading loading-lg"></div>
  <div v-if="currentArticle.id == this.$route.params.id" class="pt-2">
    <div>
      <h3>{{ currentArticle.title }}</h3>
      <img :src="currentArticle.urlToImage" class="img-responsive my-2" />
      <p>{{ currentArticle.description }}</p>
      <small>Published by {{ currentArticle.source.name }} on {{ currentArticle.publishedAt }}</small>
      <div class="py-2">
        <a v-bind:href="currentArticle.url" class="my-2" target="_blank">Go to article...</a>
        <div class="float-right">
          <button class="btn" v-show="userAuth.signedIn" @click="displayModal = true">Submit annotation</button>
        </div>
      </div>
    </div>
    <div v-for="annotation_id in currentArticle.annotations" :key="annotation_id">
      <Annotation :annotation_id="annotation_id" />
    </div>
    <div v-show="displayModal" class="modal active" id="modal-id">
      <a href="#close" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a @click="displayModal = false" class="btn btn-clear float-right" aria-label="Close"></a>
          <div class="modal-title h5">Create an annotation</div>
        </div>
        <div class="modal-body">
          <div class="content">
            <AnnotationForm @submitted="annotationSubmitted()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import AnnotationForm from '../components/AnnotationForm.vue'
  import Annotation from '../components/Annotation.vue'

  export default {
    name: 'ArticleView',
    components: {
      Annotation,
      AnnotationForm
    },
    data() {
      return {
        isReady: false,
        displayModal: false,
      }
    },
    methods: {
      ...mapActions(['fetchArticle']),
      annotationSubmitted() {
        this.displayModal = false
        this.fetchArticle(this.$route.params.id);
      }
    },
    computed: mapGetters(["currentArticle", "userAuth"]),
    created() {
        this.fetchArticle(this.$route.params.id);
    },
    mounted () {
      window.scrollTo(0, 0)
    }
  }
</script>

<style>
  .loading {
    height: 50vh;
  }
</style>