<template>
  <div class="docs-sidebar off-canvas-sidebar" :class="{ 'active': this.interfaceOptions.showSidebar }" id="sidebar">
    <div class="docs-brand">
      <a class="docs-logo c-hand" @click="$router.push('/')"><img src="/assets/spectre-logo.svg">
        <h2>CRITIK</h2>
      </a>
    </div>
    <div class="docs-nav">
      <div class="accordion-container">
        <div class="accordion">
          <input id="accordion-order-by" type="checkbox" checked="checked" name="docs-accordion-checkbox" hidden />
          <label class="accordion-header c-hand" for="accordion-order-by">Sort by</label>
          <div class="accordion-body">
            <ul class="menu menu-nav">
              <li v-for="option, prettyOption in availableFilters.sortBy" :key="option"
              @click="setFilter('sortBy', option)"  class="menu-item c-hand">
                <a :class="option == selectedFilters.sortBy ? 'text-primary' : '' ">{{ prettyOption }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="accordion">
          <input id="accordion-news-categories" type="checkbox" checked="checked" name="docs-accordion-checkbox" hidden />
          <label class="accordion-header c-hand" for="accordion-news-categories">Categories</label>
          <div class="accordion-body">
            <ul class="menu menu-nav">
              <li v-for="category, prettyCategory in availableFilters.categories" :key="category"
              @click="setFilter('category', category)" class="menu-item c-hand">
                <a :class="category == selectedFilters.category ? 'text-primary' : '' ">{{ prettyCategory }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="accordion">
          <input id="accordion-news-countries" type="checkbox" checked="checked" name="docs-accordion-checkbox" hidden />
          <label class="accordion-header c-hand" for="accordion-news-countries">Countries</label>
          <div class="accordion-body">
            <ul class="menu menu-nav">
              <li v-for="countryCode, country in availableFilters.countries" :key="countryCode"
              @click="setFilter('country', countryCode)"  class="menu-item c-hand">
                <a :class="countryCode == selectedFilters.country ? 'text-primary' : '' ">{{ country }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a @click="hideSidebar()" class="off-canvas-overlay"></a>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sidebar",
  computed: mapGetters(["availableFilters", "selectedFilters", "interfaceOptions", "userAuth"]),
  methods: {
    ...mapActions(['updateFilters', 'fetchNews', 'updateInterfaceOptions', 'setError']),
    setFilter(type, option) {
      if (option == 'recommended' && !this.userAuth.signedIn) {
        let error = {
          isPresent: true,
          title: "You can't access that",
          message: "The 'Recommended' filter is only available for logged in users. Please login and try again."
        }
        this.setError(error)
        return
      }
      this.selectedFilters[type] = option
      this.updateFilters(this.selectedFilters)
      this.fetchNews({ auth: this.$auth })
      this.$router.push('/')
    },
    hideSidebar() {
      this.interfaceOptions.showSidebar = false
      this.updateInterfaceOptions(this.interfaceOptions)
    }
  }
}
</script>
